import {
  WppActionButton,
  WppIconAdd,
  WppIconHistory,
  WppIconGear,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'
import { answerIsLoadingSignal } from 'utils/signals'

interface Props {
  onSelect: (item: AssistantTabs) => void
}

const ListItems = [
  {
    tab: AssistantTabs.CHAT,
    icon: <WppIconAdd slot="icon-start" color="var(--wpp-grey-color-800)" />,
    label: 'New Chat',
    tooltip: 'New chat',
  },
  {
    tab: AssistantTabs.HISTORY,
    icon: <WppIconHistory slot="icon-start" color="var(--wpp-grey-color-800)" />,
    label: 'History',
    tooltip: 'Chat history',
  },
  {
    tab: AssistantTabs.SETTINGS,
    icon: <WppIconGear slot="icon-start" color="var(--wpp-grey-color-800)" />,
    label: 'Settings',
    tooltip: 'Chat settings',
  },
]

export const AssistantContextMenu = ({ onSelect }: Props) => {
  const [disableButton, setDisableButton] = useState(false)
  answerIsLoadingSignal.subscribe(v => {
    if (v !== disableButton) {
      setTimeout(() => {
        setDisableButton(v)
      }, 10)
    }
  })

  return (
    <>
      <Flex gap={2}>
        {ListItems.map((item, idx) => (
          <WppTooltip text={item.tooltip} key={idx}>
            <WppActionButton
              disabled={item.tab === AssistantTabs.CHAT && disableButton}
              variant="secondary"
              onClick={() => onSelect(item.tab)}
            >
              {item.icon && item.icon}
            </WppActionButton>
          </WppTooltip>
        ))}
      </Flex>
    </>
  )
}
